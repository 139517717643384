/* For styling phone number input in superadmin page */
.PhoneInputCountry {
  padding: 6px;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #e2e8f0;
}

.PhoneInputCountrySelect {
  padding: 0;
}

.PhoneInputCountrySelectArrow {
  margin: 0 10px 0 20px;
  color: #747575;
}

.PhoneInput .PhoneInputInput {
  border: 1px solid #e2e8f0;
  border-radius: 3px;
  padding: 6px;
  display: none;
}

.chakra-modal__content-container {
  overflow: visible !important;
}
